<!--
 * @Description:
 * @Author: 梁平贤
 * @LastEditors: zhangjiaheng zhangjiaheng@enfry.com
 * @Date: 2019-07-20 15:03:50
 * @LastEditTime: 2022-07-28 15:00:29
 -->
<template>
    <!--登陆主界面-->
    <div class="login-main">
        <div class="col-nav">
          <div class="tit" :class="showView === 'psw'?'cur':''" @click="showView = 'psw'">账号登录</div>
          <div class="tit"  :class="showView === 'psw'?'':'cur'" @click="showView = 'scan'">扫码登录</div>
        </div>
        <el-form ref="ruleForm" v-if="!appSessionId" class="login-form" :model="ruleForm" :rules="rules"
            :show-message="false">
            <div class="" v-if="showView === 'psw'">
                <el-form-item class="" prop="name">
                    <el-input v-model="ruleForm.name" class="my-input" placeholder="账号/手机号" autocomplete name="name">
                        <template slot="prefix">
                            <en-icon color="#3e90fe"  size="small"
                                name="iconzhanghao-denglu"></en-icon>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item class="" prop="password">
                    <el-input v-model="ruleForm.password" class="my-input" type="password" placeholder="密码"
                        @keyup.enter.native="onLogin('ruleForm')">
                        <template slot="prefix">
                            <en-icon color="#3e90fe"  size="small"
                                name="iconmima-denglu"></en-icon>
                        </template>
                    </el-input>
                </el-form-item>
                <div class="tar">
                  <a class="txt-forget" @click="updateData('forget')">忘记密码？</a>
                </div>

                <el-form-item class="">
                    <el-button :loading="requestLoading" class="login-btn" type="primary" @click="onLogin('ruleForm')">
                        登录
                    </el-button>
                </el-form-item>
            </div>
            <!--扫码登录-->
            <scan-login v-if="showView === 'scan'" @changeView="showView = 'psw'" @goLogin="scanLogin" />
            <div class="">
                <el-form-item class="" prop="pin">
                    <div class="pin-box" />
                    <el-input v-show="validateVisible && showView === 'psw'" v-model="ruleForm.validateCode"
                        placeholder="验证码" @keyup.enter.native="onLogin('ruleForm')">
                        <template slot="prefix">
                            <en-icon color="#3e90fe" style="margin-top: 18px;" size="small"
                                name="iconyanzhengma"></en-icon>
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item class="">
                    <div class="pin-box" />
                    <img height="54" v-show="validateVisible && showView === 'psw'" @click="getValidateCode"
                        :src="validateImage" />
                </el-form-item>
                <!-- <el-form-item class=" col-nav" v-if="showView === 'psw'">
                    <span class="txt-smlogin" v-if="showView === 'scan'" @click="changeView('psw')">帐号登录</span>
                    <a class="txt-smlogin" v-if="showView === 'psw'" @click="changeView('scan')">{{$t('扫码登录')}}</a>
                    <en-icon name="qiehuanfangshi-denglu" size="16px" style="color:#3cb5fc;margin-top:-4px;"></en-icon>
                    <a class="txt-forget" @click="updateData('forget')">忘记密码？</a>
                </el-form-item> -->
            </div>
        </el-form>
    </div>
</template>
<script>
    import {
        mapActions,
        mapMutations,
        mapGetters
    } from "vuex";
    import {
        loginService,
        publicService
    } from "@/api";
    import API from "@/api/homeMenu";
    import ScanLogin from "../scanLogin/ScanLogin.vue";

    export default {
        name: "Login",
        components: {
            ScanLogin
        },
        data() {
            return {
                requestLoading: false, // 登录按钮的加载动画控制
                isDirectLogin: true, // 是否是直接访问的该网页,而不是通过跳转
                redirect: "", // 登录成功后需要跳转的路由地址
                validateImage: "",
                validateVisible: false,
                ruleForm: {
                    name: "",
                    password: "",
                    validateCode: "",
                    active: 0
                },
                pinForm: {
                    tell: null
                },
                rules: {
                    name: [{
                            required: true,
                            message: "请输入用户名",
                            trigger: true
                        },
                        {
                            min: 6,
                            max: 20,
                            message: "用户名长度在 6 到 20 个字符",
                            trigger: true
                        }
                    ],
                    password: [{
                            required: true,
                            message: "请输入密码",
                            trigger: true
                        },
                        {
                            min: 6,
                            max: 16,
                            message: "密码长度在 6 到 16 个字符",
                            trigger: true
                        }
                    ]
                },
                showView: "psw"
            };
        },
        computed: {
            ...mapGetters(["loginAccount", "componyMeta"]),
            appSessionId() {
                return this.$route.query.appSessionId;
            }
        },
        mounted() {
            // 获取路由的查询信息,所有跳转过来的页面都会带一个跳转地址,如果存在跳转地址,
            // 则在成功登录后跳转回去原网址,如果不存在跳转地址,则根据登录后的权限类型(用户,管理员,超管)
            // 跳转到对应的url去
            // 大屏展示 账号密码 放到url中 拿到账号密码 后直接登录
            // zjh 本地存储一个标识 autoShow 为 1 如果 第一登录进来 拿到的是1 就直接大屏展示
            window.localStorage.setItem("autoShow", "1");
            const {
                redirect,
                appSessionId,
                tenantId,
                account,
                password
            } = this.$route.query;
            if (account && password) {
                this.ruleForm.name = account;
                this.ruleForm.password = password;
                this.ruleForm.experience = "1";
                this._login();
            }
            if (redirect) {
                this.redirect = decodeURIComponent(redirect);
            }
            if (appSessionId) {
                localStorage.setItem("appSessionId", appSessionId);
                localStorage.setItem("tenantId", tenantId);
                this.loginBySessionId();
            }
            this.isDirectLogin = !redirect;
        },
        methods: {
            ...mapActions(["login", "logout", "saveUrlParam", "saveLoginAccount"]),
            ...mapMutations("main", ["setMenuData"]),
            async loginBySessionId() {
                const res = await loginService.getUserInfo({
                    loginFlag: "001"
                });
                console.log(res);
                res.appSessionId = this.appSessionId;
                this.login(res);

                const data = await API.getMenu();
                this.setMenuData(data);

                const urlConfig = await publicService.getUploadUrl();
                this.saveUrlParam(urlConfig);
                this.$router.push("/home");
                this.saveLoginAccount(res.mobileNo);
            },
            async getValidateCode() {
                /* eslint-disable */
                const res = await loginService.getValidateCode({
                    appSessionId: this.sessionId
                });
                console.log(res);
                const blob = new Blob([res.data]);
                this.validateImage = window.URL.createObjectURL(blob);
                // this.validateImage = res.data;
            },
            /**
             * 登录事件
             * @returns {Promise<void>}
             * @private
             */
            onLogin(formName) {
                this.$refs[formName].validate((valid, error) => {
                    if (valid) {
                        this._login();
                    } else {
                        const errorMessage = Object.values(error)[0][0].message;
                        this.$message.error(errorMessage);
                    }
                });
            },
            /**
             * 登录请求方法
             * @returns {Promise<void>}
             * @private
             */
             async _login() {
                try {
                    this.requestLoading = true;
                    this.logout();
                    const {
                        name: account,
                        password,
                        validateCode,
                        channelcode,
                        channelType,
                        experience
                    } = this.ruleForm;
                    // let rsaPassword = encrypt(password);
                    let resobj = {
                        userName:account,
                        password,
                        // validateCode,
                        // channelcode,
                        // channelType,
                        appSessionId: this.sessionId,
                        // password
                    }
                    if (experience) resobj.experience = experience;
                    const res = await loginService.login(resobj);
                    res.appSessionId = res.jwtToken
                    sessionStorage.setItem("jwtToken", res.jwtToken)
                    sessionStorage.setItem("refreshToken", res.refreshToken)
                    localStorage.setItem("USERINFO", JSON.stringify(res.userInfo))
                    localStorage.setItem('tenantId',res.userInfo.tenantId)

                     await loginService.clChannelSetQuery({tenantId:res.userInfo.tenantId});


                    // 暂不支持管理端登录
                    // if (res.userInfo.isSysManager === "001" || res.userInfo.isSysSuperManager === "001") {
                    //     this.$message.warning("暂不支持管理员登录");
                    //     return;
                    // }
                    this.login(res);
                    const urlConfig = await publicService.getUploadUrl();
                    this.saveUrlParam(urlConfig);

                    const data = await API.getMenu();
                    this.setMenuData(data);
                    console.log(this.loginAccount);
                    const canRedirect = !this.loginAccount || this.loginAccount === account;
                    this.saveLoginAccount(account);
                    if (["15000000013", "15000000011", "15000000012"].indexOf(account) > -1) {
                        // 大屏展示 特殊处理 的手机号 登录后直接进入大屏
                        window.localStorage.setItem("autoShow", "2");
                        if (window.location.hostname === "en2.enfry.com" || window.location.hostname ===
                            "en3.enfry.com") {
                            // 副屏
                            this.$router.push({
                                path: "/indexVice"
                            });
                            window.localStorage.setItem("sameScreenAuthType", "indexVice");
                        } else {
                            // 主屏
                            this.$router.push({
                                path: "/indexFull"
                            });
                            window.localStorage.setItem("sameScreenAuthType", "indexFull");
                        }
                    } else if (this.redirect && canRedirect) {
                        window.location.href = this.redirect;
                    } else {
                        this.$router.push("/home");
                    }
                } catch (e) {
                    console.error(e);
                    if (e.message?.indexOf("密码错误") !== -1 || e.message === "请输入验证码" || e.message === "验证码错误") {
                        this.validateVisible = true;
                        if (e.appSessionId) {
                            this.sessionId = e.appSessionId;
                        }
                        this.getValidateCode();
                    }
                    this.requestLoading = false;
                }
            },


            // redirectUrl() {
            //   // 获取登录账户的权限,跳转到指定的页面
            //   if (this.isDirectLogin) { // 直接登录
            //     if (this._permission.length) {
            //       const targetUrl = window.location.origin + baseUrlOfPermission[this._permission];
            //       window.location.href = targetUrl;
            //     } else {
            //       this.loginErr("登录信息出错,请重新登录");
            //     }
            //   } else { // 跳转登录
            //     window.location.href = this.redirect;
            //   }
            // },
            /**
             * 切换组件
             */
            forgetPassword() {
                // 传参方法
                this.$emit("forgetPassword");
            },
            updateData(data) {
                // 传参方法
                this.$emit("updateView", data);
            },
            changeView(data) {
                this.showView = data;
                this.ruleForm.password = "";
            },
            /**
             * @description: 扫码登录
             * @param {*}
             * @return {*}
             */
            scanLogin(data) {
                console.log("scanLogin", data);
                this.ruleForm = data;
                this._login();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .login-main {


        .pin-hide {
            visibility: hidden;
        }

        .txt {
            width: 532px;
            height: 94px;
            margin: 0 auto;

            img {
                width: 100%;
            }
        }

        .erweima-main {
            display: table;
            margin: 0 auto;
            position: relative;
            text-align: center;
        }
    }

/deep/ .el-form-item__content .el-input__prefix{
          left: -6px;
          top:-3px;
        }

    .sec-txt {
        color: #fff;
        font-size: 15px;
    }

    .erweima-main {
        display: table;
        margin: 0 auto;
        position: relative;
        text-align: center;
    }

    .login-form {
        // margin-top: 65px;
    }

    .line {
        display: flex;
        margin: 0 auto;
        width: 872px;
    }

    .col {
        display: inline-block;
        margin-right: 20px;

        & /deep/ .el-input__inner,
        & /deep/ .el-input {
            width: 100%;
            height: 100%;
        }

        .el-input {
            height: 54px;
            width: 340px;
        }

        .login-input {
            height: 54px;

            input {
                height: 100%;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        .pin-box {
            width: 110px;
            height: 100%;
            border: 1px solid red;
            margin-right: 220px;
            visibility: hidden;
        }

        .txt-smlogin {
            font-size: 16px;
            color: #fff;
            cursor: pointer;
        }



        .el-input /deep/ .el-input__inner {
            height: 54px;
        }
    }

    .col-nav {
        .en-icon {
            margin: 0 10px 0 5px;
            display: inline-block;
            vertical-align: middle;
        }
        .tit.cur {
            color: #49bef2;
            border-bottom: 2px solid #49bef2;
        }
        .tit {
            display: inline-block;
            color: #fff;
            font-size: 16px;
            width: 50%;
            text-align: center;
            height: 50px;
            line-height: 50px;
            border-bottom: 1px solid #eee;
            cursor: pointer;
            margin-bottom: 30px;
        }
    }

    .login-btn {
        height: 50px;
        width: 100%;
        background: #3e90fe;
        text-align: center;
        color: #fff;
    }

    .sure-btn {
        text-align: center;
        height: 45px;
        width: 100%;
        background: #3e90fe;
        color: #fff;
        letter-spacing: 5px;
    }
    .tar{
        text-align: right;
        margin-bottom: 10px;
        .txt-forget {
            font-size: 12px;
            color: #fff;
            cursor: pointer;
            font-weight: 500;
        }
    }
</style>
<style lang="scss">
 .my-input{
      height: 52px !important;
      input{
        height: 52px !important;
      }
    }

</style>
