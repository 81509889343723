<template>
  <div class="login-card">
    <div class="tar">
      <div class="title">
        <div class="poab" v-if="showView === 'scan'">
          <img src="@/assets/images/psw.png" alt="" @click="showView = 'psw'"/>
        </div>
        <div class="poab" v-if="showView === 'psw'">
          <img src="@/assets/images/scan.png" alt="" @click="showView = 'scan'"/>
        </div>
        <div class="myPoab" v-if="showView === 'psw'">
          扫码登录 安全快捷
        </div>
      </div>
      <div v-if="showView === 'psw'">
        <div class="tabs-login-card">
          <el-tabs v-model="showView" @tab-click="handleClick">
            <el-tab-pane label="账号登陆" name="psw"></el-tab-pane>
            <el-tab-pane label="扫码登录" name="scan"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="content">
          <el-form ref="ruleForm" v-if="!appSessionId" class="login-form" :model="ruleForm" :rules="rules"
              :show-message="false">
              <el-form-item class="col" prop="name">
            <el-input class="mt27" placeholder="请输入账号/手机号" v-model="ruleForm.name">
              <template slot="prepend" class="input-label">账号/手机号</template>
            </el-input>
          </el-form-item>
            <el-form-item class="col" prop="password">
            <el-input class="" type="password" placeholder="请输入密码" v-model="ruleForm.password" @keyup.enter.native="onLogin('ruleForm')">
              <template slot="prepend">密码</template>
            </el-input>
          </el-form-item>
          </el-form>

        </div>
        <div class="login mt27">
          <el-button :loading="requestLoading" size="small" type="primary" @click="onLogin('ruleForm')">登录</el-button>
        </div>
        <div class="tac mt27">
          <el-checkbox v-model="checked">我已阅读并同意 <a href="" class="color-blue">《隐私政策》</a> </el-checkbox>
        </div>
      </div>
      <div v-if="showView === 'scan'">
        <scan-login  @changeView="showView = 'psw'" @goLogin="scanLogin" />
      </div>
    </div>
  </div>
</template>

<script>
    import {
        mapActions,
        mapMutations,
        mapGetters
    } from "vuex";
    import {
        loginService,
        publicService
    } from "@/api";
    import API from "@/api/homeMenu";
    import ScanLogin from "./scanLogin/ScanLogin.vue";

    export default {
        name: "Login",
        props: {
          saveChannelInfo: {
            type: Object,
            default: () => {}
          }
  },
        components: {
            ScanLogin
        },
        data() {
            return {
                checked: true,
                requestLoading: false, // 登录按钮的加载动画控制
                isDirectLogin: true, // 是否是直接访问的该网页,而不是通过跳转
                redirect: "", // 登录成功后需要跳转的路由地址
                validateImage: "",
                validateVisible: false,
                ruleForm: {
                    name: "",
                    password: "",
                    validateCode: "",
                    active: 0
                },
                pinForm: {
                    tell: null
                },
                rules: {
                    name: [{
                            required: true,
                            message: "请输入用户名",
                            trigger: true
                        },
                        {
                            min: 6,
                            max: 20,
                            message: "用户名长度在 6 到 20 个字符",
                            trigger: true
                        }
                    ],
                    password: [{
                            required: true,
                            message: "请输入密码",
                            trigger: true
                        },
                        {
                            min: 6,
                            max: 16,
                            message: "密码长度在 6 到 16 个字符",
                            trigger: true
                        }
                    ]
                },
                showView: "psw"
            };
        },
        computed: {
            ...mapGetters(["loginAccount", "componyMeta"]),
            appSessionId() {
                return this.$route.query.appSessionId;
            }
        },
        mounted() {
            // 获取路由的查询信息,所有跳转过来的页面都会带一个跳转地址,如果存在跳转地址,
            // 则在成功登录后跳转回去原网址,如果不存在跳转地址,则根据登录后的权限类型(用户,管理员,超管)
            // 跳转到对应的url去
            // 大屏展示 账号密码 放到url中 拿到账号密码 后直接登录
            // zjh 本地存储一个标识 autoShow 为 1 如果 第一登录进来 拿到的是1 就直接大屏展示
            window.localStorage.setItem("autoShow", "1");
            const {
                redirect,
                appSessionId,
                tenantId,
                account,
                password
            } = this.$route.query;
            if (account && password) {
                this.ruleForm.name = account;
                this.ruleForm.password = password;
                this.ruleForm.experience = "1";
                this._login();
            }
            if (redirect) {
                this.redirect = decodeURIComponent(redirect);
            }
            if (appSessionId) {
                localStorage.setItem("appSessionId", appSessionId);
                localStorage.setItem("tenantId", tenantId);
                this.loginBySessionId();
            }
            this.isDirectLogin = !redirect;
        },
        methods: {
            ...mapActions(["login", "logout", "saveUrlParam", "saveLoginAccount"]),
            ...mapMutations("main", ["setMenuData"]),
            async loginBySessionId() {
                const res = await loginService.getUserInfo({
                    loginFlag: "001"
                });
                console.log(res);
                res.appSessionId = this.appSessionId;
                this.login(res);

                const data = await API.getMenu();
                this.setMenuData(data);

                const urlConfig = await publicService.getUploadUrl();
                this.saveUrlParam(urlConfig);
                this.$router.push("/home");
                this.saveLoginAccount(res.mobileNo);
            },
            async getValidateCode() {
                /* eslint-disable */
                const res = await loginService.getValidateCode({
                    appSessionId: this.sessionId
                });
                console.log(res);
                const blob = new Blob([res.data]);
                this.validateImage = window.URL.createObjectURL(blob);
                // this.validateImage = res.data;
            },
             /**
             * 登录方式切换
             * @returns {Promise<void>}
             * @private
             */
            async handleClick(){

            },
            /**
             * 登录事件
             * @returns {Promise<void>}
             * @private
             */
            onLogin(formName) {
                this.$refs[formName].validate((valid, error) => {
                    if (valid) {
                        this._login();
                    } else {
                        const errorMessage = Object.values(error)[0][0].message;
                        this.$message.error(errorMessage);
                    }
                });
            },
            /**
             * 登录请求方法
             * @returns {Promise<void>}
             * @private
             */
             async _login() {
                try {
                    this.requestLoading = true;
                    this.logout();
                    const {
                        name: account,
                        password,
                        validateCode,
                        channelcode,
                        channelType,
                        experience
                    } = this.ruleForm;
                    // let rsaPassword = encrypt(password);
                    let resobj = {
                        userName:account,
                        password,
                        // validateCode,
                        // channelcode,
                        // channelType,
                        appSessionId: this.sessionId,
                        // password
                    }
                    if (experience) resobj.experience = experience;
                    const res = await loginService.login(resobj);
                    res.appSessionId = res.jwtToken
                    sessionStorage.setItem("jwtToken", res.jwtToken)
                    sessionStorage.setItem("refreshToken", res.refreshToken)
                    localStorage.setItem("USERINFO", JSON.stringify(res.userInfo))
                    localStorage.setItem('tenantId',res.userInfo.tenantId)

                     await loginService.clChannelSetQuery({tenantId:res.userInfo.tenantId});


                    // 暂不支持管理端登录
                    // if (res.userInfo.isSysManager === "001" || res.userInfo.isSysSuperManager === "001") {
                    //     this.$message.warning("暂不支持管理员登录");
                    //     return;
                    // }
                    this.login(res);
                    const urlConfig = await publicService.getUploadUrl();
                    this.saveUrlParam(urlConfig);

                    const data = await API.getMenu();
                    this.setMenuData(data);
                    console.log(this.loginAccount);
                    const canRedirect = !this.loginAccount || this.loginAccount === account;
                    this.saveLoginAccount(account);
                    if (["15000000013", "15000000011", "15000000012"].indexOf(account) > -1) {
                        // 大屏展示 特殊处理 的手机号 登录后直接进入大屏
                        window.localStorage.setItem("autoShow", "2");
                        if (window.location.hostname === "en2.enfry.com" || window.location.hostname ===
                            "en3.enfry.com") {
                            // 副屏
                            this.$router.push({
                                path: "/indexVice"
                            });
                            window.localStorage.setItem("sameScreenAuthType", "indexVice");
                        } else {
                            // 主屏
                            this.$router.push({
                                path: "/indexFull"
                            });
                            window.localStorage.setItem("sameScreenAuthType", "indexFull");
                        }
                    } else if (this.redirect && canRedirect) {
                        window.location.href = this.redirect;
                    } else {
                        this.$router.push("/home");
                    }
                } catch (e) {
                    console.error(e);
                    if (e.message?.indexOf("密码错误") !== -1 || e.message === "请输入验证码" || e.message === "验证码错误") {
                        this.validateVisible = true;
                        if (e.appSessionId) {
                            this.sessionId = e.appSessionId;
                        }
                        this.getValidateCode();
                    }
                    this.requestLoading = false;
                }
            },


            // redirectUrl() {
            //   // 获取登录账户的权限,跳转到指定的页面
            //   if (this.isDirectLogin) { // 直接登录
            //     if (this._permission.length) {
            //       const targetUrl = window.location.origin + baseUrlOfPermission[this._permission];
            //       window.location.href = targetUrl;
            //     } else {
            //       this.loginErr("登录信息出错,请重新登录");
            //     }
            //   } else { // 跳转登录
            //     window.location.href = this.redirect;
            //   }
            // },
            /**
             * 切换组件
             */
            forgetPassword() {
                // 传参方法
                this.$emit("forgetPassword");
            },
            updateData(data) {
                // 传参方法
                this.$emit("updateView", data);
            },
            changeView(data) {
                this.showView = data;
                this.ruleForm.password = "";
            },
            /**
             * @description: 扫码登录
             * @param {*}
             * @return {*}
             */
            scanLogin(data) {
                console.log("scanLogin", data);
                this.ruleForm = data;
                this._login();
            }
        }
    };
</script>

<style lang="scss" scoped>
.login-card {
  width: 100%;
  display: flex;
  justify-content: right;
  .tabs-login-card {
    margin-top: 100px;
    font-size: 22px;
  }

  .tar {
    width: 570px;
    height: 500px;
    background: #ffffff;
    border-radius: 27px;
    padding: 0 60px;
    margin-right: 106px;
    position: relative;
    .poab {
      position: absolute;
      right: 0;
      cursor: pointer;
    }
    .myPoab {
      position: absolute;
      right: 116px;
      top: 34px;
      width: 169px;
      height: 42px;
      line-height: 42px;
      border-radius: 4px;
      text-align: center;
      color: #fff;
      background: #3685f2;
      display: none;
    }

    .myPoab::after {
      content: "";
      position: absolute;
      top: 16px;
      right: -9px;
      width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #3685f2;
      transform: rotate(90deg);
      display: none;
    }
    .poab:hover + .myPoab {
      display: block;
    }
    .poab:hover + .myPoab::after {
      display: block;
    }
  }
  .content {
    .el-input {
      .el-input-group__prepend {
        width: 200px;
        text-align: right;
      }
      .el-input__inner {
        height: 40px;
      }
    }
  }
  .login {
    .el-button {
      width: 100%;
    }
  }
  .tac {
    text-align: center;
  }
  .mt27 {
    margin-top: 27px;
  }
}
</style>
<style lang="scss">
.login-card {
  .content {
    .el-input {
      .el-input-group__prepend {
        width: 116px;
        text-align: right;
        background-color: #fff;
      }
      .el-input__inner {
        height: 40px;
        font-size: 14px;
      }
    }
  }
  .el-tabs__nav {
    margin: 0 auto;
  }
  .el-tabs__nav-scroll {
    display: flex;
    text-align: center;
  }
  .el-tabs__item {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
  }
  .el-tabs .el-tabs__nav-wrap:after {
    height: 0;
  }
}
.color-blue{
  color: #3685f2;
}
</style>
